import React, { useRef } from 'react';
import { Popup } from '@components/Popup';
import { Formik } from 'formik';
import axiosTransport from '@actions/axios';
import Dropzone from 'react-dropzone';
import classNames from 'classnames/bind';
import { FormOptions } from '@components/forms';
import styles from './AddDocumentPopup.module.scss';

const cx = classNames.bind(styles);

const FieldError = ({ errors, field }) => {
    if (!errors[field]) {
        return null;
    }
    return (
        <div className={styles['form__error']}>{errors[field]}</div>
    );
};

export function AddDocumentPopup({ isOpen, onSubmitHandler, exitOnClickHandler }) {

    const submitButtonClass = cx({
        'form__button': true,
        'form__button--primary__btn': true,
    });

    const options = [
        {
            label: 'Yes',
            value: 1
        },
        {
            label: 'No',
            value: 0
        }
    ];

    if(!isOpen){
        return null;
    }

    return (    
        <Popup title="Add Document" exitOnClickHandler={exitOnClickHandler}>
            <Formik
                initialValues={{
                    document_name: '',
                    use_in_introduction_email: options[1]
                }}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                    const formData = new FormData();
                    formData.append('document_name', values.document_name);
                    formData.append('document', values.document);
                    formData.append('use_in_introduction_email', values.use_in_introduction_email.value == 1 ? 'true' : 'false');
                    
                    try {
                        setSubmitting(true);
                        await axiosTransport.post('/documents', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        if (onSubmitHandler) {
                            onSubmitHandler();
                        }
                    } catch (err) {
                        setTimeout(() => setSubmitting(false), 4000);
                        if (err.response.data) {
                            const errors = err.response.data.details || err.response.data;
                            errors.map((error) => {
                                setFieldError(error.path[0], error.message);
                            });
                        }
                    }
                }}
                >
                {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={styles['form__row']}>
                            <label className={styles['form__label']}>Document Name</label>
                            <input className={styles['form__input']} type="text" name="document_name" value={values.document_name} onChange={handleChange} />
                            <FieldError errors={errors} field="document_name" />
                        </div>
                        <div className={styles['form__row']}>
                            <label className={styles['form__label']}>Document</label>
                            <Dropzone onDrop={(files) => setFieldValue('document', files[0])}>
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className={styles['file-upload']}
                                        {...getRootProps({
                                            onChange: (e) => setFieldValue('document', e.target.files[0])
                                        })}
                                        >
                                        <input {...getInputProps()} />
                                        {values.document ? values.document.name : 'Click here or drag file to upload'}
                                        <span className={styles['allowed-formats']}>Allowed file types: doc, docx and pdf. (20MB limit per file)</span>
                                </div>
                                )}
                            </Dropzone>
                            <FieldError errors={errors} field="document" />
                        </div>

                        <div className={styles['form__row']}>
                            <label className={styles['form__label']}>Use in Introduction Email?</label>
                            <FormOptions 
                                options={options.map((type) => {
                                    return {
                                        label: type.label,
                                        value: type.id,
                                        ...type
                                    };
                                })}
                                selectedOption={values.use_in_introduction_email}
                                setValueHandler={(option) => setFieldValue('use_in_introduction_email', option)}
                                groupKey="use_in_introduction_email"
                            />
                        </div>
                        <button className={submitButtonClass} type="submit" disabled={isSubmitting}>{isSubmitting ? 'Uploading' : 'Add'}</button>
                    </form>
                )}
                
            </Formik>
        </Popup>
    );
}
