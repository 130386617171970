import React from 'react';
import LayoutWithMenu from '@components/layout_with_menu';
import { useAuth } from '@hooks/useAuth';
import { Documents } from '@components/Documents';
import { SEO } from '@components/SEO';

function AppPage(){
    
    const auth = useAuth();

    return (
        <LayoutWithMenu>
            <SEO title="Documents" />
            <Documents />
        </LayoutWithMenu>
    );
};

export default AppPage;
