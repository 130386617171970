import React from "react"
import PropTypes from "prop-types"
import Header from "./header";
import { MenuComponent } from '@components/Menu';

import 'react-dates/lib/css/_datepicker.css';
import '@styles/global.scss';

const LayoutWithMenu = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        <div className="main__layout">
          <div className="left__column">
            <MenuComponent />
          </div>
          <div className="middle__column">{children}</div>
          <div className="right__column"></div>
        </div>
      </main>
      
    </>
  )
}

LayoutWithMenu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWithMenu;
