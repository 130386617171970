import React from 'react';
import CloseIcon from '@components/icons/close';
import classNames from 'classnames/bind';
import styles from './Popup.module.scss';

const cx = classNames.bind(styles);

export const Popup = ({ children, className, title, exitOnClickHandler, matchContent = false }) => {
    const popupInnerClass = cx({
        'popup__inner': true,
        'popup__inner--match-content': Boolean(matchContent),
        [className]: Boolean(className)
    });
    return (
        <div className={styles['popup__wrapper']}>
            <div className={styles['popup__container']}>
                <div className={popupInnerClass}>
                    <button className={styles['popup__exitbtn']} onClick={exitOnClickHandler}>
                        <div className={styles['popup__exitbtn__icon']}>
                            {CloseIcon}
                        </div>
                    </button>
                    <h2 className={styles['popup__content__title']}>{title}</h2>
                    <div className={styles['popup__content__container']}>
                        {children}
                    </div>
                </div>
            </div>    
        </div>
    );
};

export const PopupConfirm = ({ onConfirmHandler, onCancelHandler, message }) => {
    const popupWrapperClass = cx({
        'popup__wrapper': true,
        'popup__wrapper--confirm-action': true,
    });
    return (
        <div className={popupWrapperClass}>
            <div className={styles['popup__container']}>
                <div className={styles['popup__inner']}>
                    <div className={styles['popup__content__container']}>
                        <div className={styles['form__row']}>
                            <p>{message}</p>
                        </div>
                        <div className={`${styles['form__row']} ${styles['form__row--buttons']}`}>
                            <button className={`${styles['form__button']} ${styles['form__button--primary__btn']}`} type="button" onClick={onConfirmHandler}>Confirm</button>
                            <button className={`${styles['form__button']} ${styles['form__button--secondary__btn']}`} type="button" onClick={onCancelHandler}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
