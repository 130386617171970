import React, { useState } from 'react';
import useSwr, { mutate } from 'swr';
import axiosTransport from '@actions/axios';
import { AddDocumentPopup } from '@components/AddDocumentPopup';
import { PopupConfirm } from '@components/Popup';
import DeleteIcon from '@components/icons/delete';
import ViewIcon from '@components/icons/eye';
import PdfIcon from '@components/icons/document_type_pdf';
import DocIcon from '@components/icons/document_type_doc';
import styles from './Documents.module.scss';

const fetcher = (url) => axiosTransport.get(url).then(({ data }) => data);

const documentIcons = {
    pdf: PdfIcon,
    'vnd.openxmlformats-officedocument.wordprocessingml.document': DocIcon,
    msword: DocIcon
};

const DocumentContainerHeader = () => {

    return (
        <div className={styles['documents__header']}>
            <div className={styles['documents__header__type']}>Type</div>
            <div className={styles['documents__header__name']}>Document Name</div>
            <div className={styles['documents__header__use']}>Used For</div>
            <div className={styles['documents__header__actions']}></div>
        </div>
    );
};

const DocumentRow = ({document, deleteDocumentAction}) => {

    const ext = document.document_mimetype.split('/')[1];

    return (
        <div className={styles['document']}>
            <div className={styles['document__details']}>
                <div className={styles['document__ext']}>{documentIcons[ext]}</div>
                <div className={styles['document__name']}>{document.document_name}</div>
                <div className={styles['document__use']}>{document.use_in_introduction_email ? 'Introduction Email' : 'Other'}</div>
            </div>
            <div className={styles['document__actions']}>
                <a onClick={() => deleteDocumentAction(document)} className={styles['document__action']}>
                    {DeleteIcon}
                </a>
                <a target="_blank" rel="noreferrer" href={document.document_url} className={styles['document__action']}>
                    {ViewIcon}
                </a>
            </div>
        </div>
    );
};

export const Documents = () => {

    const [showAddDocument, setShowAddDocument] = useState(false);
    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const { data: documents, error } = useSwr('/documents', fetcher);

    const isLoading = (!documents && !error);

    const onSubmitHandler = () => {
        mutate('/documents');
        exitOnClickHandler();
    };

    const exitOnClickHandler = () => {
        setShowAddDocument(false);
    };

    const deleteDocumentAction = (document) => {
        setDocumentToDelete(document);
        setShowConfirmDeletePopup(true);
    };

    const deleteDocument = async (id) => {
        try {
            await axiosTransport.delete(`/documents/${id}`);
            setShowConfirmDeletePopup(false);
            setDocumentToDelete(null);
            mutate('/documents');
        } catch (err) {
            
        }
    };

    return (
        <>
            <AddDocumentPopup isOpen={showAddDocument} onSubmitHandler={onSubmitHandler} exitOnClickHandler={exitOnClickHandler} />
            {showConfirmDeletePopup ?
                <PopupConfirm 
                    onConfirmHandler={() => deleteDocument(documentToDelete.id)}
                    onCancelHandler={() => setShowConfirmDeletePopup(false)}
                    message={`Are you sure you want to delete "${documentToDelete.document_name}".`} 
                /> : null}
            <div className="component__wrapper">
                <div className="component__inner">
                    <div className="component__wrapper__header">
                        <h1>Documents</h1>
                        <div className="component__wrapper__actions">
                                <button 
                                    className="action__btn action__btn--white" 
                                    onClick={() => setShowAddDocument(true)}
                                    data-tip="Add Document"
                                >Add Document</button>
                        </div>
                    </div>
                    <div className="component__container">
                        {error ? <div className={styles['document']}>Error getting documents, please refresh!</div> : null}
                        {isLoading ? <div className={styles['document']}>You have no documents.</div> : null}
                        {documents &&
                            <div className={styles['documents__container']}>
                                <DocumentContainerHeader />
                                {documents.map((document, idx) => <DocumentRow document={document} deleteDocumentAction={deleteDocumentAction} />)}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Documents;